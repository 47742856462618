import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import StoreService from "./StoreService";

// Thunks
export const createStore = createAsyncThunk(
  "productStore/createStore",
  async (storeData, thunkAPI) => {
    try {
      return await StoreService.createStore(storeData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || "Failed to create store");
    }
  }
);

export const getStores = createAsyncThunk(
  "productStore/getStores",
  async (_, thunkAPI) => {
    try {
      return await StoreService.getStores();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || "Failed to fetch stores");
    }
  }
);

export const getEachUserStores = createAsyncThunk(
  "productStore/getEachUserStores",
  async (id, thunkAPI) => {
    try {
       
      return await StoreService.getEachUserStores(id);
      
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || "Failed to fetch user stores");
    }
  }
);

export const updateStore = createAsyncThunk(
  "productStore/updateStore",
  async (storeData, thunkAPI) => {
    try {
      return await StoreService.updateStore(storeData);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message || "Failed to update store");
    }
  }
);

export const deleteStore = createAsyncThunk(
  "productStore/deleteStore",
  async (id, thunkAPI) => {
    try {
      return await StoreService.deleteStore(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || "Failed to delete store");
    }
  }
);

export const getStore = createAsyncThunk(
  "productStore/getStore",
  async (id, thunkAPI) => {
    try {
      return await StoreService.getStore(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message || "Failed to fetch store");
    }
  }
);

export const resetState = createAction("RevertAll");

// Initial state
const initialState = {
  stores: [],
  createdStore: null,
  updatedStore: null,
  deletedStore: null,
  storeName: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

// Slice
export const storeSlice = createSlice({
  name: "stores",
  initialState, // Use the initialState from above
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStores.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.stores = action.payload; // Handle store data
      })
      .addCase(getStores.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(createStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdStore = action.payload;
      })
      .addCase(createStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getEachUserStores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.stores = action.payload; // Ensure this updates the stores array correctly
      })
      .addCase(updateStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedStore = action.payload;
      })
      .addCase(updateStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedStore = action.payload;
      })
      .addCase(deleteStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.storeName = action.payload;
      })
      .addCase(getStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(resetState, () => initialState); // Reset state on reset
  },
});

// Exporting the reducer
export default storeSlice.reducer;
