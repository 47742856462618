// userService.js
import axios from 'axios';
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

// Fetch all users by role
const getUsers = async (role) => {
  const response = await axios.get(`${base_url}user/userList?role=${role}`, config); // Use query parameter
  console.log(response.data)
  return response.data;
};
const getAllUsers = async () => {
  console.log("hello")
  const response = await axios.get(`${base_url}user/all-users`, config); // Use query parameter
  console.log(response.data)
  return response.data;
};


// Fetch a single user by ID and role
const getUserById = async (userId,) => {
  const response = await axios.get(`${base_url}user/${userId}`, config); 
  console.log(response.data)
  return response.data;
};

// Create a new user for a specified role (if needed)
const createUser = async (userData, role) => {
  const response = await axios.post(`${base_url}user/${role}`, userData, config); // Use role in the URL
  return response.data;
};

// Update user status or details based on role
const updateUserStatus = async (userId, status, role) => {
  const response = await axios.put(`${base_url}user/${role}/${userId}/status`, { status }, config); // Use role in the URL
  return response.data;
};

// Update user profile
const updateUserProfile = async (userId, profileInfo ) => {
  const response = await axios.put(`${base_url}user/${userId}`, { profileInfo}, config);
  console.log(response.data)
  return response.data;
};
const updateUserProfilePicture = async (userId, profilepic ) => {
  const response = await axios.put(`${base_url}user/userpic/${userId}`, { profilepic }, config);
  console.log(profilepic);
  return response.data;
};
// Delete a user based on role
const deleteUser = async (userId, role) => {
  const response = await axios.delete(`${base_url}user/${role}/${userId}`, config);
  return response.data;
};
const getUserCount = async () => {
  const response = await axios.get(`${base_url}user/userCounts`, config);
  console.log(response.data)
  return response.data;
};
// Export all functions as userService
const userService = {
  getUsers,
  getUserById,
  createUser,
  updateUserStatus,
  deleteUser,
  getUserCount,
  updateUserProfile,
  getAllUsers,
  updateUserProfilePicture,
};

export default userService;
