import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderT from "./HeaderT";
import FooterB from "./FooterB";
import { useNavigate } from "react-router-dom";
import {
  fetchAssignedOrders,
  updateOrderStatus,
} from "../../features/orders/orderSlice";
import { Package, MapPin, Phone, User,BadgeCheck,  CheckCircle, Loader2 } from "lucide-react";

const Orders = () => {
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const deliveryPersonId = user?._id;

  const { orders, isLoading, isError, message } = useSelector(
    (state) => state.orders
  );
  useEffect(() => {// Get user from local storage
    if (!user) {
      navigate("/"); 
      
    }
  }, [navigate]);

  useEffect(() => {
    if (deliveryPersonId) {
      dispatch(fetchAssignedOrders(deliveryPersonId));
    }
  }, [dispatch, deliveryPersonId]);

  const handleChangeStatus = async (orderId) => {
    await dispatch(updateOrderStatus({ orderId, status: "active" }));
    dispatch(fetchAssignedOrders(deliveryPersonId));
  };

  // Filter out delivered orders
  const filteredOrders = orders.filter(order => order.orderStatus !== "delivered");

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Loader2 className="w-16 h-16 text-blue-500 animate-spin" />
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <HeaderT />
      <div className="container mx-auto p-4">
        <h3 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Assigned Orders 📦
        </h3>

        {filteredOrders.length === 0 ? (
          <div className="text-center text-gray-600">
            No orders assigned.
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredOrders.map((order) => (
              <div
                key={order._id}
                className="bg-white rounded-lg p-6 shadow-lg transition-transform transform hover:scale-105"
              >
                <div className="flex items-center justify-between mb-4">
                  <h4 className="text-xl font-semibold text-gray-800 flex items-center gap-2">
                    <Package className="text-blue-500" />
                    <span>Order ID:</span>
                    <span className="text-blue-600">{order._id.slice(-6)}</span>
                  </h4>
                  <span
                    className={`px-3 py-1 text-sm font-semibold rounded-full ${
                      order.orderStatus === "active"
                        ? "bg-green-100 text-green-600"
                        : "bg-yellow-100 text-yellow-600"
                    }`}
                  >
                    {order.orderStatus.toUpperCase()}
                  </span>
                </div>

                <div className="space-y-2 text-gray-700 mb-4">
                  <p className="flex items-center gap-2">
                    <BadgeCheck className="text-purple-500" />
                    <strong>City:</strong> {order.city}
                  </p>
                  <p className="flex items-center gap-2">
                    <MapPin className="text-red-500" />
                    <strong>Address:</strong> {order.address}
                  </p>
                  <p className="flex items-center gap-2">
                    <User className="text-blue-500" />
                    <strong>Customer:</strong> {order.first_name} {order.last_name}
                  </p>
                  <p className="flex items-center gap-2">
                    <Phone className="text-green-500" />
                    <strong>Phone:</strong> {order.phone_number}
                  </p>
                </div>

                {order.orderStatus === "active" && (
                  <div className="bg-green-100 text-green-700 p-3 rounded-lg mb-4 flex items-center gap-2">
                    <CheckCircle className="w-5 h-5" />
                    Order is active! Please deliver to the customer.
                  </div>
                )}

                {order.orderStatus !== "active" && (
                  <button
                    onClick={() => handleChangeStatus(order._id)}
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 rounded-lg transition-colors flex items-center justify-center gap-2"
                  >
                    <CheckCircle className="w-5 h-5" />
                    Accept Order
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <FooterB assignedOrderCount={filteredOrders.length}/>
    </div>
  );
};

export default Orders;
