// services/conversationService.js
import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getconversations = async (userId) => {
  const response = await axios.get(`${base_url}conversations/${userId}`, config);
  return response.data;
};

const conversationService = {
  getconversations, // Exports the correct method
};

export default conversationService;
