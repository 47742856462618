import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import customerReducer from "../features/cutomers/customerSlice";
import productReducer from "../features/product/productSlice";
import brandReducer from "../features/brand/brandSlice";
import pCategoryReducer from "../features/pcategory/pcategorySlice";
import pSubCategoryReducer from "../features/pSubCategory/pSubCategorySlice";
import bSubCategoryReducer from "../features/bSubCategory/bSubCategorySlice";
import tagReducer from "../features/tags/tagsSlice";
import bCategoryReducer from "../features/bcategory/bcategorySlice";
import blogReducer from "../features/blogs/blogSlice";
import colorReducer from "../features/color/colorSlice";
import enquiryReducer from "../features/enquiry/enquirySlice";
import uploadReducer from "../features/upload/uploadSlice";
import couponReducer from "../features/coupon/couponSlice";
import deliveryBoyReducer from "../features/delivery/deliveryBoySlice";
import orderReducer from '../features/orders/orderSlice'; // Adjust path as necessary

// New 
import sizeReducer from "../features/sizes/sizeSlice";
import reportIssueReducer from '../features/Report/reportIssueSlice';
import storeReducer from "../features/Store/StoreSlice";
import registerReducer from "../features/Register/registerslice";
import resetPasswordReducer from "../features/ResetPassword/ResetPasswordSlice";
import userListReducer from "../features/UserList/UserListSlice";
import ActivityReducer from "../features/Activity/ActivitySlice";
import DocumentReducer from "../features/Document/DocumentSlice";
import chatReducer from "../features/Chat/ChatSlicer";
import messageReducer from "../features/message/messageSlice";
import coneverastionReducer from "../features/conversation/conversationSlice";
import PackageReducer from "../features/package/packageSlicer.js" 

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    product: productReducer,
    brand: brandReducer,
    pCategory: pCategoryReducer,
    pSubcategory: pSubCategoryReducer,
    bSubcategory: bSubCategoryReducer,
    tag: tagReducer,
    bCategory: bCategoryReducer,
    blogs: blogReducer,
    color: colorReducer,
    enquiry: enquiryReducer,
    upload: uploadReducer,
    coupon: couponReducer,
    deliveryBoy: deliveryBoyReducer,
    orders: orderReducer,
    size :sizeReducer,
    //new
    reportIssue: reportIssueReducer,
    store: storeReducer,
    register: registerReducer,
    resetPassword: resetPasswordReducer,
    userList: userListReducer,
    activity: ActivityReducer,
    document: DocumentReducer,
    Chat : chatReducer,
    message:messageReducer,
    coneverastion: coneverastionReducer,
    package :PackageReducer,

  },
});
