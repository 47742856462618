import React , {useEffect} from "react";
import { useSelector } from "react-redux";
import { User, Mail, Phone, BadgeCheck, ShieldCheck, UserCircle } from "lucide-react";
import HeaderT from "./HeaderT";
import FooterB from "./FooterB";
import { useNavigate } from "react-router-dom";

const Dprofile = () => {
   const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

useEffect(() => {
    if (!user) {
      navigate("/"); 
      
    }
  }, [navigate]);

  return (
    <div className="min-h-screen bg-white">
      <HeaderT />
      <div className="flex flex-col items-center py-6 px-4 mt-20">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl">
          <h3 className="text-center text-2xl font-bold mb-6 text-gray-800">
            Delivery Profile 🚴‍♂️
          </h3>

          <div className="flex flex-col items-center">
            {/* Profile Icon */}
            <UserCircle className="w-24 h-24 text-blue-500 mb-4" />

            {/* User Details */}
            <div className="text-gray-700 space-y-4 text-lg">
              <p className="flex items-center gap-2">
                <User className="text-green-500 w-6 h-6" />
                <strong>Name:</strong> {user?.firstname} {user?.lastname}
              </p>
              <p className="flex items-center gap-2">
                <Mail className="text-blue-500 w-6 h-6" />
                <strong>Email:</strong> {user?.email}
              </p>
              <p className="flex items-center gap-2">
                <Phone className="text-red-500 w-6 h-6" />
                <strong>Mobile:</strong> {user?.mobile}
              </p>
              <p className="flex items-center gap-2">
                <ShieldCheck className="text-purple-500 w-6 h-6" />
                <strong>Role:</strong> {user?.role}
              </p>
              <p className="flex items-center gap-2 text-green-600 mt-3">
                <BadgeCheck className="w-6 h-6" />
                <span className="text-lg">Verified Delivery Personnel</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterB />
    </div>
  );
};

export default Dprofile;
