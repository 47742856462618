import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import activityService from './ActivityService';

// Async thunk to fetch all activities
export const getActivities = createAsyncThunk(
  'activity/getActivities',
  async (role, thunkAPI) => {
    try {
      // Pass the role parameter to the service function
      const response = await activityService.getActivities(role);
      return response;
    } catch (error) {
      // Handle error and return a default error message if the response data is unavailable
      return thunkAPI.rejectWithValue(
        error?.response?.data || 'Failed to fetch activities.'
      );
    }
  }
);



export const getAllActivities = createAsyncThunk(
  "activity/getAllActivities",
  async (_, { rejectWithValue }) => {
    try {
      const data = await activityService.getAllActivities();
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching activities");
    }
  }
);
// Async thunk to fetch activities for a specific admin
export const getUserActivities = createAsyncThunk(
  'activity/getUserActivities',
  async (adminId, thunkAPI) => {
    try {
      const response = await activityService.getUserActivities(adminId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data || `Failed to fetch activities for admin ID: ${adminId}`
      );
    }
  }
);

export const getEachActivities = createAsyncThunk(
  'activity/getEachActivities',
  async (id, thunkAPI) => {
    try {
      const response = await activityService.getEachActivities(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data || `Failed to fetch activities for admin ID: ${id}`
      );
    }
  }
);

export const UpdateActivitie = createAsyncThunk(
  'activity/UpdateActivitie ',
  async (data, thunkAPI) => {
      try {
          return await activityService.UpdateActivitie(data);
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);
export const getUnreadActivities = createAsyncThunk(
  "activity/getAllActivities",
  async (_, { rejectWithValue }) => {
    try {
      const data = await activityService.getUnreadActivities();
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching activities");
    }
  }
);

// Initial state
const initialState = {
  activityLogs: [], // Store activity logs
  Allactivities:[],
  Eachactivities:[],
  loading: false,   // Loading state
  error: null,      // Error message
};

// Activity slice
const activitySlice = createSlice({
  name: 'activity',

  initialState,
  reducers: {
    resetActivityState: (state) => {
      state.activityLogs = [];
      state.Allactivities = [];
        state.UnreadActivity = [];
        state.extraReducers = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all activities
      .addCase(getActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.activityLogs = action.payload;
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch activities.';
      })

      // Get activities for a specific admin
      .addCase(getUserActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.activityLogs = action.payload;
      })
      .addCase(getUserActivities.rejected, (state, action) => {
        state.loading = false;
        state.error =
        action.payload || 'Failed to fetch activities for the specified admin.';
      }) 
      .addCase(getAllActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.Allactivities = action.payload;
      })
      .addCase(getAllActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      
      
      .addCase(UpdateActivitie.pending, (state) => {
        state.loading = true;
    })
    .addCase(UpdateActivitie.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.isSuccess = true;
        state.updatedActivity = action.payload;
    })
    .addCase(UpdateActivitie.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.Success = false;
        state.message = action.error;
    })
      .addCase(getEachActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEachActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.Eachactivities = action.payload.activityLogs;
      })
      .addCase(getEachActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetActivityState } = activitySlice.actions;

export default activitySlice.reducer;
