// redux/slices/conversationsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import conversationService from './conversationService'; // Import default export

// Fetch conversations using the correct method
export const fetchConversations = createAsyncThunk(
  'conversations/fetchConversations',
  async (userId, thunkAPI) => {
    try {
      const response = await conversationService.getconversations(userId); // Corrected method call
      return response; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); // Handle error properly
    }
  }
);

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload || []; 
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message; // Handle the error
      });
  },
});

export default conversationsSlice.reducer;
