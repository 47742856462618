import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getPackages = async () => {
    const response = await axios.get(`${base_url}package/`, config);
    return response.data;
};

const createPackage = async (packageData) => {
    console.log(packageData)
    const response = await axios.post(`${base_url}package/`, packageData, config);
    return response.data;
};

const getPackage = async (id) => {
    const response = await axios.get(`${base_url}package/${id}`, config);
    return response.data;
};

const deletePackage = async (id) => {
    const response = await axios.delete(`${base_url}package/${id}`, config);
    return response.data;
};

const updatePackage = async (packageData) => {
    const response = await axios.put(
        `${base_url}package/${packageData.id}`,
        {
            name: packageData.packageData.name,
            duration: packageData.packageData.duration,
            amount: packageData.packageData.amount,
        },
        config
    );
    return response.data;
};

const packageService = {
    getPackages,
    createPackage,
    getPackage,
    deletePackage,
    updatePackage,
};

export default packageService;
