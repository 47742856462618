import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import packageService from "./packageService";

// Thunks for async operations
export const getPackages = createAsyncThunk(
    "package/get-packages",
    async (_, thunkAPI) => {
        try {
            return await packageService.getPackages();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const createPackage = createAsyncThunk(
    "package/create-package",
    async (packageData, thunkAPI) => {
        try {
            return await packageService.createPackage(packageData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const updatePackage = createAsyncThunk(
    "package/update-package",
    async (packageData, thunkAPI) => {
        try {
            return await packageService.updatePackage(packageData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deletePackage = createAsyncThunk(
    "package/delete-package",
    async (id, thunkAPI) => {
        try {
            return await packageService.deletePackage(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getPackage = createAsyncThunk(
    "package/get-package",
    async (id, thunkAPI) => {
        try {
            return await packageService.getPackage(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const resetState = createAction("package/resetState");

const initialState = {
    packages: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const packageSlice = createSlice({
    name: "packages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPackages.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.packages = action.payload;
            })
            .addCase(getPackages.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(createPackage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createPackage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.createdPackage = action.payload;
            })
            .addCase(createPackage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updatePackage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updatePackage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.updatedPackage = action.payload;
            })
            .addCase(updatePackage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deletePackage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deletePackage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.deletedPackage = action.payload;
            })
            .addCase(deletePackage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getPackage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPackage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.packageDetails = action.payload;
            })
            .addCase(getPackage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    },
});

export default packageSlice.reducer;
