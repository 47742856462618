import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";


const getStores = async () => {
   
    const response = await axios.get(`${base_url}store/`);
    return response.data;
};

const getEachUserStores = async (id) => {
    if (!id) {
        throw new Error("User ID is required to fetch user-specific stores");
    }
    try {
        const response = await axios.get(`${base_url}store/storeList/${id}`, config);

        return response.data;
    } catch (error) {
        const errorMessage = error.response?.data?.message || "Failed to fetch user-specific stores";
        throw new Error(errorMessage);
    }
};

const createStore = async (stores) => {
    console.log(stores)
   
    const response = await axios.post(`${base_url}store/`, stores, config);
    
    return response.data;
};

const getStore = async (id) => {
    const response = await axios.get(`${base_url}store/${id}`, config);
    return response.data;
};

const deleteStore = async (id) => {
    const response = await axios.delete(`${base_url}store/${id}`, config);
    return response.data;
};

const updateStore = async (store) => {
    console.log(store)
    const response = await axios.put(
        `${base_url}store/${store.id}`,
        { storeName: store.pStoreData.storeName,
        address:store.pStoreData.address,
        userId:store.userId,
        },
        config
    );
    console.log(response)
    return response.data;
};

const storeService = {
    getStores,
    createStore,
    getStore,
    deleteStore,
    updateStore,
    getEachUserStores,
};

export default storeService;
