import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssignedOrders,
  updateOrderStatus,
} from "../../features/orders/orderSlice";
import { Truck, ClipboardList, CheckCircle, Loader2 } from "lucide-react"; // Icons
import HeaderT from "./HeaderT";
import FooterB from "./FooterB";
import { useNavigate } from "react-router-dom";

const Ddashboard = () => {
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const deliveryPersonId = user?._id;
  const { orders, isLoading } = useSelector((state) => state.orders);

  useEffect(() => {// Get user from local storage
    if (!user) {
      navigate("/"); 
      
    }
  }, [navigate]);
  
  useEffect(() => {
    if (deliveryPersonId) {
      dispatch(fetchAssignedOrders(deliveryPersonId));
    }
  }, [dispatch, deliveryPersonId]);

  // Summary Data
  const totalOrders = orders?.length || 0;
  const pendingDeliveries = orders?.filter((order) => order.orderStatus === "assigned").length || 0;
  const completedDeliveries = orders?.filter((order) => order.orderStatus === "delivered").length || 0;
  const nonCompletedDeliveries = orders?.filter((order) => order.orderStatus !== "delivered").length || 0;
if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Loader2 className="w-16 h-16 text-blue-500 animate-spin" />
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-white">
      <HeaderT />
      <div className="container mx-auto py-10 px-4 md:px-8">
        {/* Dashboard Title */}
        <h1 className="text-3xl font-bold text-gray-800 flex items-center mb-6">
          <Truck className="w-8 h-8 text-green-600 mr-2" /> Delivery Dashboard
        </h1>

        {/* Overview Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white shadow-lg p-6 rounded-lg flex items-center">
            <ClipboardList className="w-12 h-12 text-blue-500" />
            <div className="ml-4">
              <h2 className="text-2xl font-bold">{totalOrders}</h2>
              <p className="text-gray-600">Total Orders</p>
            </div>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg flex items-center">
            <Loader2 className="w-12 h-12 text-yellow-500" />
            <div className="ml-4">
              <h2 className="text-2xl font-bold">{pendingDeliveries}</h2>
              <p className="text-gray-600">Pending Deliveries</p>
            </div>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg flex items-center">
            <CheckCircle className="w-12 h-12 text-green-500" />
            <div className="ml-4">
              <h2 className="text-2xl font-bold">{completedDeliveries}</h2>
              <p className="text-gray-600">Completed Deliveries</p>
            </div>
          </div>
        </div>

        {/* Orders List */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-20">
          <h2 className="text-xl font-bold bg-gray-300 p-4">Assigned Orders</h2>
          {isLoading ? (
            <div className="text-center p-6 text-gray-500">
              <Loader2 className="w-6 h-6 animate-spin mx-auto mb-2" />
              Loading orders...
            </div>
          ) : orders?.length > 0 ? (
            <div className="divide-y">
              {orders.map((order) => (
                <div key={order._id} className="p-4 flex justify-between items-center">
                  <div>
                    <p className="text-lg font-semibold">📦 Order #{order._id.slice(-6)}</p>
                    <p className="text-sm text-gray-500">🚛 {order.city}, {order.country}</p>
                    <p className="text-sm text-gray-500">📞 {order.phone_number}</p>
                  </div>
                  <span
                    className={`px-3 py-1 text-sm font-bold rounded-full ${
                      order.orderStatus === "assigned"
                        ? "bg-yellow-200 text-yellow-800"
                        : "bg-green-200 text-green-800"
                    }`}
                  >
                    {order.orderStatus.toUpperCase()}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center p-6 text-gray-500">No assigned orders.</p>
          )}
        </div>
      </div>
      <FooterB assignedOrderCount={nonCompletedDeliveries}/>
    </div>
  );
};

export default Ddashboard;
