import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const uploadImg = async (data) => {
  try {
    const response = await axios.post(
      `${base_url}upload/create`,
      data,
      {
        ...config,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 600000,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      
      console.error("Server Error:", error.response.data);
    } else if (error.request) {
      console.error("Network Error or Timeout:", error.message);
    } else {
    }
    throw error;
  }
};

const deleteImg = async (id) => {
  try {
    const response = await axios.delete(
      `${base_url}upload/delete-img/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting image:", error.message);
    throw error;
  }
};

const uploadService = {
  uploadImg,
  deleteImg,
};

export default uploadService;
