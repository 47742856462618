import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssignedOrders } from "../../features/orders/orderSlice";
import { PackageCheck, Calendar, Truck, MapPin, BadgeCheck, Loader2 } from "lucide-react";
import HeaderT from "./HeaderT";
import FooterB from "./FooterB";
import { useNavigate } from "react-router-dom";

const Dhistory = () => {
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const deliveryPersonId = user?._id;
  const { orders, isLoading } = useSelector((state) => state.orders);

  // Fetch only delivered orders
 useEffect(() => {// Get user from local storage
    if (!user) {
      navigate("/"); 
      
    }
  }, [navigate]);
  useEffect(() => {
    if (deliveryPersonId) {
      dispatch(fetchAssignedOrders(deliveryPersonId));
    }
  }, [dispatch, deliveryPersonId]);

  const deliveredOrders = orders.filter((order) => order.orderStatus === "delivered");
  const nonCompletedDeliveries = orders?.filter((order) => order.orderStatus !== "delivered").length || 0;

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Loader2 className="w-16 h-16 text-blue-500 animate-spin" />
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-white">
      <HeaderT />
      <div className="flex flex-col items-center py-5 px-4">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-4xl">
          <h3 className="text-center text-2xl font-bold mb-6 text-gray-800">
            Delivery History 📦
          </h3>

          {isLoading ? (
            <div className="text-center text-gray-600">
              <Loader2 className="w-6 h-6 animate-spin inline-block mr-2" />
              Loading history...
            </div>
          ) : deliveredOrders.length === 0 ? (
            <div className="text-center text-gray-600">
              No delivered orders yet.
            </div>
          ) : (
            <ul className="space-y-6">
              {deliveredOrders.map((order) => (
                <li
                  key={order._id}
                  className="border rounded-lg p-6 shadow-md bg-white flex flex-col gap-4"
                >
                  {/* Order Header */}
                  <div className="flex items-center justify-between">
                    <h4 className="text-lg font-bold flex items-center gap-2">
                      <PackageCheck className="text-green-500 w-6 h-6" />
                      Order ID: <span className="text-gray-600">{order._id.slice(-6)}</span>
                    </h4>
                    <span className="px-3 py-1 text-sm font-bold rounded-full bg-green-200 text-green-800">
                      DELIVERED
                    </span>
                  </div>

                  {/* Order Details */}
                  <div className="text-gray-700 space-y-2">
                    <p className="flex items-center gap-2">
                      <MapPin className="text-red-500 w-5 h-5" />
                      <strong>Address:</strong> {order.address}, {order.city}, {order.country}
                    </p>
                    <p className="flex items-center gap-2">
                      <Truck className="text-blue-500 w-5 h-5" />
                      <strong>Total Price:</strong> {order.totalPrice.toFixed(2)} ETB
                    </p>
                    <p className="flex items-center gap-2">
                      <Calendar className="text-purple-500 w-5 h-5" />
                      <strong>Delivered At:</strong> {new Date(order.updatedAt).toLocaleString()}
                    </p>
                  </div>

                  {/* Confirmation Badge */}
                  <div className="flex items-center gap-2 text-green-600 mt-3">
                    <BadgeCheck className="w-6 h-6" />
                    <span className="text-sm">Successfully Delivered</span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <FooterB assignedOrderCount={nonCompletedDeliveries}/>
    </div>
  );
};

export default Dhistory;
