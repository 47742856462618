import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import messageService from './messageService';

// Fetch messages for a conversation
export const fetchMessages = createAsyncThunk(
  'messages/fetchMessages',
  async ({ conversationId, senderId, receiverId }, thunkAPI) => {
    try {
      const messages = await messageService.fetchMessages(conversationId, senderId, receiverId);
      return { messages, receiverId, conversationId };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Send a new message
export const sendMessage = createAsyncThunk(
  'messages/sendMessage',
  async (messageData, thunkAPI) => {
    try {
      const newMessage = await messageService.sendMessage(messageData);
      return newMessage;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Delete a message
export const deleteMessage = createAsyncThunk(
  'messages/deleteMessage',
  async (messageId, thunkAPI) => {
    try {
      await messageService.deleteMessage(messageId);
      return messageId;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    data: [],
    receiver: null,
    conversationId: null,
    loading: false,
    error: null,
  },
  reducers: {
    setMessages: (state, action) => {
      state.data = action.payload.messages;
      state.receiver = action.payload.receiver;
      state.conversationId = action.payload.conversationId;
    },
    addMessage: (state, action) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Messages
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.messages;
        state.receiver = action.payload.receiverId;
        state.conversationId = action.payload.conversationId;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Send Message
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload); // Add the new message to the state
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Message
      .addCase(deleteMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((message) => message.id !== action.payload);
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setMessages, addMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
