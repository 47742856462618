import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import productService from "./productService";

export const getProducts = createAsyncThunk(
  "product/get-products",
  async (thunkAPI) => {
    try {
      return await productService.getProducts();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const getNotApprovedProducts = createAsyncThunk(
  "product/get-Not-approved-products",
  async (status,thunkAPI) => {
    try {
      return await productService.getNotApprovedProducts(status);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const getAProduct = createAsyncThunk(
  "blog/get-product",
  async (id, thunkAPI) => {
    try {
      return await productService.getProduct(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createProducts = createAsyncThunk(
  "product/create-products",
  async (productData, thunkAPI) => {
    try {
      return await productService.createProduct(productData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteAProduct = createAsyncThunk(
  "product/delete-product",
  async (id,userId, thunkAPI) => {
    try {
      return await productService.deleteproduct({id,userId});
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAProduct = createAsyncThunk(
  "product/update-product",
  async (brand, thunkAPI) => {
    try {
      return await productService.updateProduct(brand);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


// new 
export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async (merchantId, thunkAPI) => {
    try {
      const data = await productService.fetchDashboardData(merchantId);
      return data; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchRecentProducts = createAsyncThunk(
  "product/fetchRecentProducts",
  async (merchantId, thunkAPI) => {
    try {
      const data = await productService.fetchRecentProducts(merchantId); 
     
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); 
    }
  }
);


export const SuperAdminfetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async (__, thunkAPI) => {
    try {
    
      const data = await productService.SuperAdminfetchDashboardData();
      return data; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); 
    }
  }
);

export const SuperAdminfetchRecentProducts = createAsyncThunk(
  "product/fetchRecentProducts",
  async (__, thunkAPI) => {
    try {
      const data = await productService.SuperAdminfetchRecentProducts();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); 
    }
  }
);

export const getStoreProducts = createAsyncThunk(
  "product/getStoreProducts",
  async (storeId,thunkAPI) => {
    try {
      return await productService.getStoreProducts(storeId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const getEachMechantProduct = createAsyncThunk(
  "product/get-eachMerchantproduct",
  async (MerchantId, thunkAPI) => {
    try {
      
      return await productService.getEachMechantProduct(MerchantId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateProductStatus = createAsyncThunk(
  "product/update-product-status", // Use a unique action type here
  async (productData, thunkAPI) => {
    try {
      return await productService.updateProductStatus(productData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);export const getProduct = createAsyncThunk(
  "product/get-product",
  async (id, thunkAPI) => {
    try {
      return await productService.getProduct(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);




export const resetState = createAction("Reset_all");

const initialState = {
  products: [],
  selectedProduct: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const productSlice = createSlice({
  name: "product",
  products:[],

  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getNotApprovedProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotApprovedProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getNotApprovedProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdProduct = action.payload;
      })
      .addCase(createProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.productName = action.payload.title;
        state.productDesc = action.payload.description;
        state.productPrice = action.payload.price;
        state.productBrand = action.payload.brand;
        state.productCategory = action.payload.category;
        state.productTag = action.payload.tags;
        state.productColors = action.payload.color;
        state.productQuantity = action.payload.quantity;

        state.productImages = action.payload.images;
      })
      .addCase(getAProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedProduct = action.payload;
      })
      .addCase(deleteAProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedProduct = action.payload;
      })
      .addCase(updateAProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      // new

      .addCase(fetchDashboardData.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload || [];
        
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload || "Failed to fetch data";
      })
      .addCase(fetchRecentProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRecentProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recentProducts = action.payload || []; // Ensure the payload is correctly assigned
      })
      .addCase(fetchRecentProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message; 
      })
      .addCase(getEachMechantProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEachMechantProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess=true;
        state.productsMerchant = action.payload || []; // Assign fetched data to the state
      })
      .addCase(getEachMechantProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload; // Set the error message
      })
      .addCase(getStoreProducts.pending, (state) => {
        state.isLoading = true; // Set loading state to true
        state.error = null; // Clear previous errors
      })
      .addCase(getStoreProducts.fulfilled, (state, action) => {
        state.isLoading = false; // Loading is complete
        state.products = action.payload; 
      })
      .addCase(getStoreProducts.rejected, (state, action) => {
        state.isLoading = false; // Stop loading
        state.error = action.payload || "Failed to fetch store products"; // Save error message
      })
      .addCase(getProduct.pending, (state) => {
        state.isLoading = true; // Set loading state to true
        state.error = null; // Clear previous errors
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isLoading = false; // Loading is complete
        state.selectedProduct = action.payload; 
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isLoading = false; // Stop loading
        state.error = action.payload || "Failed to fetch store products"; // Save error message
      })

      
      .addCase(resetState, () => initialState);
  },
});
export default productSlice.reducer;
