import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const getProducts = async () => {
  const response = await axios.get(`${base_url}product/`);
  return response.data;
};
const getNotApprovedProducts = async (status) => {
  let endpoint = `${base_url}product/NotApproved`; // Default to pending
  
  // Adjust endpoint based on the status
  if (status === "approved") {
    endpoint = `${base_url}product/Approved`;
  } else if (status === "rejected") {
    endpoint = `${base_url}product/Rejected`;
  }
  console.log(endpoint)
 

  try {
    const response = await axios.get(endpoint);
    console.log(response);
    return response.data; // return the data to be used in the component
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

const createProduct = async (product) => {
  const response = await axios.post(`${base_url}product/`, product, config);

  return response.data;
};

const getProduct = async (id) => {
  
  const response = await axios.get(`${base_url}product/${id}`, config);
  
  return response.data;
};

const updateProduct = async (product) => {
  const response = await axios.put(
    `${base_url}product/${product.id}`,
    {
      title: product.productData.title,
      description: product.productData.description,
      price: product.productData.price,
      brand: product.productData.brand,
      quantity: product.productData.quantity,
      category: product.productData.category,
      tags: product.productData.tags,
      color: product.productData.color,
      images: product.productData.images,
    },
    config
  );

  return response.data;
};

const deleteproduct = async (id) => {
  const user=id.id;
  const userId=user.userId;
  const productid=user.productId;
 
  const requestConfig = {
    ...config, 
    data: {
      userId, 
    },
  };
  
  const response = await axios.delete(`${base_url}product/${productid}`, requestConfig);
  console.log(response.data)

  return response.data;
};




//new


const fetchDashboardData = async (merchantId) => {
  try {
    const response = await axios.get(`${base_url}product/MerchantdashBoard/${merchantId}`);
    return response.data;  
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

const  fetchRecentProducts = async (merchantId) => {
  try {
    const response = await axios.get(`${base_url}product/recent/${merchantId}`);
    console.log("fetchRecentProducts ",response.data);
     return response.data
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};


const  getEachMechantProduct = async (merchantId) => {
  try {
    const response = await axios.get(`${base_url}product/AllProduct/${merchantId}`);
    
    return response.data;  
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

const SuperAdminfetchDashboardData = async () => {
  try {
    const response = await axios.get(`${base_url}product/MerchantdashBoard`);
    
    return response.data;  
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

const  SuperAdminfetchRecentProducts = async () => {
  try {
    const response = await axios.get(`${base_url}product/recent`);
   
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};


const getStoreProducts = async (storeId) => {
try{
  const response = await axios.get(`${base_url}product/store/${storeId}`, config);
  
  return response.data;}
  catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
};

export const updateProductStatus = async (payload) => {
  try {
    const response = await axios.put(`${base_url}product/${payload.productId}`, {
      ProductApproved: payload.ProductApproved,
      rejectionReason: payload.rejectionReason || null,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating product status:', error);
    throw error.response?.data || error.message;
  }
};


const productService = {
  getProducts,
  createProduct,
  deleteproduct,
  updateProduct,
  getProduct,

  //new
  fetchRecentProducts,
  fetchDashboardData,
  getEachMechantProduct,
  SuperAdminfetchDashboardData,
  SuperAdminfetchRecentProducts,
  getStoreProducts,
  updateProductStatus,
  getNotApprovedProducts,
};

export default productService;
