// features/orders/orderSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { base_url } from "../../utils/baseUrl";

export const fetchAssignedOrders = createAsyncThunk(
  "orders/fetchAssignedOrders",
  async (deliveryPersonId) => {
    const response = await axios.get(`${base_url}delivery/assigned/${deliveryPersonId}`);
    return response.data;
  }
);

export const updateOrderStatus = createAsyncThunk(
  "orders/updateOrderStatus",
  async ({ orderId, status }) => {
    const response = await axios.put(`${base_url}delivery/order/${orderId}/status`, { status });
    return response.data;
  }
);

const orderSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    isLoading: false,
    isError: false,
    message: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
   
    
      .addCase(fetchAssignedOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAssignedOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        
        // If the response contains a message and empty orders array, set orders to an empty array
        if (Array.isArray(action.payload)) {
          state.orders = action.payload;
        } else {
          state.orders = action.payload.orders || [];
        }
      })
      .addCase(fetchAssignedOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedOrder = action.payload.order;
        const index = state.orders.findIndex((order) => order._id === updatedOrder._id);
        if (index !== -1) {
          state.orders[index].orderStatus = updatedOrder.orderStatus;
        }
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message;
      });
      
  },
});

export default orderSlice.reducer;
