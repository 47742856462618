import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getReports = async () => {
  const response = await axios.get(`${base_url}report/`, config);
  return response.data;
};

const createReport = async (reportData) => {
  const response = await axios.post(`${base_url}report`, reportData, config);
  return response.data;
};

const updateReport = async (report) => {
  const response = await axios.put(
    `${base_url}report/${report.id}`,
    { title: report.title, description: report.description },
    config
  );
  return response.data;
};

const getReport = async (id) => {
  const response = await axios.get(`${base_url}report/${id}`, config);
  return response.data;
};

const deleteReport = async (id) => {
  const response = await axios.delete(`${base_url}report/${id}`, config);
  return response.data;
};

const reportService = {
  getReports,
  createReport,
  updateReport,
  getReport,
  deleteReport,
};

export default reportService;
