import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";


const getActivities = async (role) => {
  
  const response = await axios.get(`${base_url}activity/role/${role.role}`);

  return response.data;
};

// Get activities for a specific admin
const getUserActivities = async (id) => {
  const response = await axios.get(`${base_url}activity/admin/${id}`, config);
 
  return response.data.activityLogs; 
};
// Get activities
const getAllActivities = async () => {
  
  const response = await axios.get(`${base_url}activity/`, config);
  return response.data; 
};
const  getUnreadActivities = async () => {
  
  const response = await axios.get(`${base_url}activity/unread`, config);
  return response.data; 
};

const getEachActivities = async (id) => {
 
  const response = await axios.get(`${base_url}activity/ActivityDetail/${id}`, config);
  
  return response.data; 
};
const UpdateActivitie = async (data) => {
  try {
    console.log(data)
    const response = await axios.put(
      `${base_url}activity/${data}`,
      {
      
        Isread: true, 
      },
      config 
    );
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error updating activity:", error.response?.data || error.message);
    throw error; 
  }
};


const activityService = {
  getActivities,
  getUserActivities,
  getAllActivities,
  getEachActivities,
  UpdateActivitie,
  getUnreadActivities,
  
};

export default activityService;
