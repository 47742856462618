import axios from 'axios';
import { base_url } from '../../utils/baseUrl';  // Make sure this is set correctly
import { config } from '../../utils/axiosconfig'; // Config for headers, e.g., authorization

// Fetch messages for a conversation
const fetchMessages = async (conversationId, senderId, receiverId) => {
  try {
    const response = await axios.get(
      `${base_url}/message/${conversationId}?senderId=${senderId}&receiverId=${receiverId}`,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : 'Error fetching messages');
  }
};

// Send a new message
const sendMessage = async (messageData) => {
  try {
    const response = await axios.post(
      `${base_url}/message`,
      messageData,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : 'Error sending message');
  }
};

// Delete a message by ID
const deleteMessage = async (messageId) => {
  try {
    const response = await axios.delete(`${base_url}/message/${messageId}`, config);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : 'Error deleting message');
  }
};

const messageService = {
  fetchMessages,
  sendMessage,
  deleteMessage,
};

export default messageService;
